header {
	height: 90px;
	width: 100%;
	top: 0;
	z-index: 999;
	background: #fff;
	position: fixed;
	transition: 0.3s;

	&.header-shadow {
		box-shadow: 0 2px 10px rgba(0,0,0,0.06);
	}
	@include media(s) {
		height: 60px;
		position: fixed;
	}

	.header-in {
		width: 100%;
		margin: 0 auto;
		@include media(s) {
			width: 100%;
		}

		.home-icon{
			width: 38px;
position: absolute;
top: 12px;
left: 13px;
		}

		.header-nav {
			nav {
				ul {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					@include media(s) {
						display: none;
						top: 60px;
						width: 100%;
						background-image: linear-gradient(-225deg, rgba(227,77,88,0.9) 10%, rgba(219,173,19,0.9) 80%);
						padding: 30px 0;
						position: absolute;
					}

					li {
						position: relative;
						letter-spacing: 0.05em;
						font-family: "Bebas Neue", cursive;
						@include media(s) {
							line-height: 50px;
							text-align: center;
							color: #fff;
							font-size: 20px;
							margin-right: 0;
						}

						a {
							transition: 0.3s;

							&:hover {
								color: #ff8000;
							}
						}

						&:last-child a {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 150px;
							height: 90px;
							position: relative;
							text-decoration: none;
							text-align: center;
							color: #fff;
							transition: 0.4s;
							background: linear-gradient(to right, #e34b08,#dbad13 150px,#dbad13 250px,#e34b08);
							background-size: 400px 300px;

							&:before {
								content: "";
								background: url("../img/mail-icon.svg") no-repeat center;
								display: inline-block;
								width: 20px;
								height: 20px;
								margin-right: 10px;
								margin-top: -4px;
							}

							&:hover {
								background-position: 100% 0;
								letter-spacing: 0.18em;
							}

							@include media(s) {
								width: 100%;
								height: auto;
								background: none;

								&:before{
									content: none;
								}
							}
						}

						&:not(:last-child) {
							margin-right: 30px;
							@include media(s) {
								margin-right: 0;
							}
						}
					}
				}
			}
		}

		.contact-sp {
			a {
				align-items: center;
				width: 60px;
				height: 60px;
				background: linear-gradient(to right, #e34b08,#dbad13 150px,#dbad13 250px,#e34b08);
				background-size: 400px 300px;
				position: fixed;
				right: 65px;

				&:before {
					content: "";
					background: url("../img/mail-icon.svg") no-repeat center;
					width: 25px;
					height: 25px;
					position: absolute;
					left: calc(50% - 25px / 2);
					top: calc(50% - 25px / 2);
				}
			}
		}
	}
	@include media(s) {
		.nav_toggle {
			display: block;
			width: 40px;
			height: 40px;
			position: absolute;
			top: 17px;
			z-index: 100;
			right: 12px;
		}

		.nav_toggle div {
			position: relative;
		}

		.nav_toggle span {
			display: block;
			height: 3px;
			background: #fa9b00;
			position: absolute;
			width: 100%;
			left: 0;
			transition: 0.3s ease-in-out;
		}

		.nav_toggle span:nth-child(1) {
			top: 0;
		}

		.nav_toggle span:nth-child(2) {
			top: 12px;
		}

		.nav_toggle span:nth-child(3) {
			top: 24px;
		}
		/*開閉ボタンopen時*/
		.nav_toggle.open span:nth-child(1) {
			top: 12px;
			-webkit-transform: rotate(135deg);
			-moz-transform: rotate(135deg);
			transform: rotate(135deg);
		}

		.nav_toggle.open span:nth-child(2) {
			width: 0;
			left: 50%;
		}

		.nav_toggle.open span:nth-child(3) {
			top: 12px;
			-webkit-transform: rotate(-135deg);
			-moz-transform: rotate(-135deg);
			transform: rotate(-135deg);
		}
	}
}
