@import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
/*--------------------------------------------------------------
	html/body
---------------------------------------------------------------*/
* {
	box-sizing: border-box;
}

body,
html {
	min-width: 1200px;
	@include media(s) {
		min-width: 100%;
	}
}

html {
	font-size: 62.5%;
	@include media(s) {
		font-size: 41.8%;
	}
}

body {
	color: #333;
	line-height: 1.8;
	font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
	word-break: break-word;
	width: 100%;
	font-weight: 300;
	font-size: 1.6rem;
}

.container {
	overflow: hidden;
}
/*--------------------------------------------------------------
	レスポンシブスイッチ
----------------------------------------------------------------*/
.pc {
	display: block;
	@include media(s) {
		display: none;
	}
}

.sp {
	display: none;
	@include media(s) {
		display: block;
	}
}
/*------------------------------------------------------
	タイトル / テキスト
--------------------------------------------------------*/
.heading-l {
	font-size: 3.6rem;
}

.heading-m {
	font-size: 3.2rem;
	text-align: center;
	margin-bottom: 20px;
	line-height: 1.5em;
}

.heading-s {
	font-size: 2.4rem;
}

.heading-ss {
	font-size: 2rem;
}

.default-txt {
	line-height: 1.8em;
}

.default-txt-s {
	font-size: 1.4rem;
	line-height: 1.8em;
}
//カラー

.wh-txt {
	color: #fff !important;
}
/*------------------------------------------------------
	ボタン
--------------------------------------------------------*/
.default-btn {
	display: inline-block;
	width: 360px;
	padding: 22px 20px 19px;
	text-align: center;
	text-decoration: none;
	outline: none;
	transition: all 0.3s;
	background: linear-gradient(to right, #e34b08,#dbad13);
	color: #fff;
	border-radius: 50px;
	position: relative;
	font-weight: 600;
	box-shadow: 0 0 14px 1px rgba(250,155,0,.5);

	&:after {
		content: '';
		position: absolute;
		top: 51%;
		right: 30px;
		width: 18px;
		height: 18px;
		transform: translate3d(0, -50%, 0);
		background-position: top left;
		background-repeat: no-repeat;
		background-image: url("../img/btn_arrow.svg");
		background-size: 18px 18px;
		transition: 0.2s;
	}

	&:hover {
		background-color: #fff;
		box-shadow: 0 0 0 0 rgba(250,155,0,.9);
		margin-top: 3px;
		margin-bottom: -3px;

		&:after {
			right: 26px;
		}
	}
	@include media(s) {
		width: 85%;
	}
}
/*------------------------------------------------------
	7アクション
--------------------------------------------------------*/
a img {
	transition: 0.2s;
}

a img:hover {
	opacity: 0.7;
}
/*------------------------------------------------------
	幅
--------------------------------------------------------*/
.section-in {
	max-width: 1100px;
	min-width: 950px;
	margin: 0 auto;
	padding: 80px 0;
	position: relative;
	@include media(l) {
		width: 95%;
	}
	@include media(s) {
		width: 100%;
		max-width: 100%;
		min-width: 100%;
		padding: 40px 20px;
	}
}
/*------------------------------------------------------
	flex レイアウト
--------------------------------------------------------*/
.flex {
	display: flex;
}

.f-sb {
	justify-content: space-between;
}

.f-ed {
	justify-content: flex-end;
}

.f-ct {
	justify-content: center;
}

.f-wp {
	flex-wrap: wrap;
}

.f-ic {
	align-items: center;
}
/*--------------------------------------------------------------
	リスト
----------------------------------------------------------------*/
.disc {
	margin-left: 1em;

	li {
		list-style-type: disc;
		text-align: left;
	}
}
/*--------------------------------------------------------------
	汎用CSS
----------------------------------------------------------------*/
span {
	font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.ttl-area {
	h3 {
		text-align: center;
		font-family: "Bebas Neue", cursive;
		font-size: 6rem;
		letter-spacing: 0.05em;
		position: relative;

		span {
			display: block;
			color: #ccc;
			font-size: 2rem;
			margin-bottom: -20px;
		}

		&:before {
			content: '';
			@include center;
			top: 90%;
			position: absolute;
			border-radius: 100px;
			width: 75px;
			height: 4px;
			background: linear-gradient(to right, #e34b08, #dbad13);
		}
	}
	@include media(s) {
		h3 {
			font-size: 5rem;

			span {
				font-size: 2rem;
				margin-bottom: -10px;
			}

			&:before {
				width: 50px;
				top: 95%;
			}
		}
	}
}

.ttl-area + p {
	margin: 20px 0 10px;
	text-align: center;
}

.coments {
	font-size: 12px;
	color: #888;
	text-align: center;
}

.main-under-v {
	margin-top: 90px;
	height: 400px;
	position: relative;
	@include media(s) {
		margin-top: 60px;
		height: 200px;
	}

	.main-under-v-in {
		@include center;
		transform: translate(-50%, -80%);

		h2 {
			color: #fff;
			font-size: 6rem;
			font-family: "Bebas Neue", cursive;
			letter-spacing: 0.07em;

			span {
				display: block;
				font-size: 16px;
				margin-top: -25px;
				letter-spacing: 0.02em;
			}
		}
		@include media(s) {
			transform: translate(-50%, -50%);

			h2 {
				span {
					display: block;
					font-size: 14px;
					margin-top: -15px;
				}
			}
		}
	}
}
//スクロールアニメーション-----------------------
.anime {
	opacity: 0;
	transform: translate(0,30px);
	transition: 0.8s;
}

.move {
	opacity: 1.0;
	transform: translate(0,0);
	-webkit-transform: translate(0,0);
}
