
//---------------------------
//	ブレイクポイント
//---------------------------

@mixin media($breakpoint) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

$breakpoints: (
	s: "only screen and (max-width: 767px)",//SP用
	l: "only screen and (max-width: 1024px)"//タブレット用
);

//----------------------------
//	font
//----------------------------

$font-sans-serif: "Noto Sans Japanese","FP-ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "Arial", "MS PGothic",osaka, sans-serif;

//----------------------------
//	上下左右中央揃え
//----------------------------

@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	text-align: center;
}

//----------------------------
//	full-width
//----------------------------
@mixin full-width(){
  margin-left: calc((100vw - 100%) / -2);
  margin-right: calc((100vw - 100%) / -2);
  max-width: auto; // IE対策
  max-width: initial;
}

/*--------------------------------------------------------------
	margin
----------------------------------------------------------------*/
//各5px刻みで生成しています
//.mT0～.mT60までを生成
@for $value from 0 through 13 {
	.mT#{$value * 5} {
		margin-top: 5px * $value !important;
	}
}
//.mB0～.mB60までを生成
@for $value from 0 through 13 {
	.mB#{$value * 5} {
		margin-bottom: 5px * $value !important;
	}
}
/* padding */
//.pT0～.pT60までを生成
@for $value from 0 through 13 {
	.pT#{$value * 5} {
		padding-top: 5px * $value !important;
	}
}
//.pB0～.pB60までを生成
@for $value from 0 through 13 {
	.pB#{$value * 5} {
		padding-bottom: 5px * $value !important;
	}
}
