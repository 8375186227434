.footer {
	background: #111;
	padding: 45px 0;
	position: relative;
	box-sizing: border-box;
	clear: both;
	@include media(s) {
		height: auto;
		padding: 25px 20px;
	}

	.footer-in {
		width: 1100px;
		margin: 0 auto;
		color: #fff;
		@include media(s) {
			width: 100%;
		}

		.footer-nav {
			li {
				font-family: "Bebas Neue", cursive;

				&:not(:last-child) {
					margin-right: 20px;
				}
			}
			@include media(s) {
				ul {
					display: none;
				}
			}
		}
		@include media(s) {
			.copy-light{
				text-align: center;
				width: 100%;
			}
		}

	}
}

.btn-to-top {
	a {
		width: 60px;
		height: 60px;
		background: linear-gradient(to right, #e34b08,#dbad13);
		position: fixed;
		right: 25px;
		bottom: 25px;
		border-radius: 100%;

		&:after {
			content: "";
			display: block;
			width: 17px;
			height: 17px;
			border-top: 4px solid #fff;
			border-right: 4px solid #fff;
			transform: rotate(-45deg);
			position: absolute;
			top: 24px;
			left: 19px;
		}
		@include media(s) {
			right: 15px;
			bottom: 15px;
		}
	}
}

.contact-area {
	.contact-btn,
	.contact-txt {
		width: 50%;
		position: relative;
		height: 350px;
		@include media(s) {
			width: 100%;
			height: auto;
		}
	}

	.contact-btn-in,
	.contact-txt-in {
		@include center;
		padding: 50px;
		width: 500px;
		color: #fff;
		@include media(s) {
			width: 100%;
			position: static;
			transform: none;
			padding: 30px;
		}
	}

	.contact-txt {
		background: #1b2638;
		display: inline-block;
		text-align: left;

		.contact-txt-in {
			height: auto;
			text-align: left;
			@include media(s) {
				text-align: center;
			}

			h3 {
				font-size: 5rem;
				letter-spacing: 0.2rem;
				font-family: "Bebas Neue", cursive;
			}
		}
	}

	.contact-btn {
		background: #101b2b;
		transition: 0.2s;

		&:hover {
			opacity: 0.9;
		}

		p {
			position: relative;
			font-size: 3rem;
			margin-top: 60px;
			@include media(s) {
				margin-top: 45px;
			}

			&:before {
				content: "";
				background: url("../img/mail-icon.svg") no-repeat center;
				display: inline-block;
				width: 60px;
				height: 60px;
				position: absolute;
				left: calc(50% - 60px / 2);
				top: -60px;
				margin-right: 10px;
				margin-top: -4px;
				@include media(s) {
					margin-right: 0;
					width: 40px;
					height: 40px;
					left: calc(50% - 40px / 2);
					top: -40px;
				}
			}
		}
	}
}
