// reset
@import "_reset";
@import "_mixin";
@import "_common";
@import "_header";
@import "_footer"; 

//--------------------------------------------
//top
//--------------------------------------------

.overlay {
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #e34b08,#dbad13);
  top: 0;
}

.overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 12px;
}

#top {
  #particles-js {
    width: 100%;
    height: 1300px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: -1;
  }

  .main-v {
    margin-top: 90px;

    .main-v-in {
      padding: 80px 0 0;

      h1 {
        width: 800px;
        margin: 0 auto 40px;
      }

      .main-v-txt {
        text-align: center;

        h2 {
          display: inline-block;
          text-align: left;
          font-size: 1.8rem;
          line-height: 2.2;
          font-weight: bold;
        }
      }
    }
    @include media(s) {
      margin-top: 60px;

      .main-v-in {
        padding: 40px 20px 20px;

        h1 {
          width: 93%;
          margin: 0 auto 40px;
        }

        .main-v-txt {
          text-align: center;

          h2 {
            display: inline-block;
            text-align: left;

            span {
              font-size: 1.8rem;
              line-height: 2.2;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  //sec01--------------------------------
  .sec01 {
    margin-bottom: 260px;

    ul {
      a {
        width: 32%;
        box-shadow: 0 16px 20px rgba(0,0,0,0.1);
        padding: 35px;
        background: #fff;
        text-align: justify;
        transition: 0.2s;
        height: 470px;
        cursor: pointer;

        &:hover {
          box-shadow: 0 6px 14px rgba(0,0,0,0.2);
          margin-top: 5px;
          margin-bottom: -5px;

          h3 {
            color: #fa9b00;

            span {
              color: #ffd5b3;
            }
          }
        }

        img {
          width: 90px;
          height: 100%;
          margin: 5px auto 25px;
          display: flex;
        }

        h3 {
          text-align: center;
          transition: 0.2s;
          font-size: 3.4rem;
          letter-spacing: 0.05em;
          font-family: "Bebas Neue", cursive;

          span {
            display: block;
            font-size: 1.6rem;
            margin-bottom: -5px;
            color: #ccc;
            transition: 0.2s;
          }
        }
      }
    }
    @include media(s) {
      margin-bottom: 20px;

      ul {
        a {
          width: 100%;
          height: auto;
          padding: 30px;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          img {
            width: 90px;
            margin: 5px auto 25px;
            display: flex;
          }

          h3 {
            text-align: center;
            transition: 0.2s;
            font-size: 3.4rem;
            letter-spacing: 0.05em;
            font-family: "Bebas Neue", cursive;

            span {
              display: block;
              font-size: 1.6rem;
              margin-bottom: -5px;
              color: #ccc;
              transition: 0.2s;
            }
          }
        }
      }
    }
  }

  .sec02,
  .sec03,
  .sec04 {
    background: #eee;
    height: auto;
    position: relative;
    margin-bottom: 320px;

    .section-in{
      padding: 40px 0;
    }

    .section-img {
      position: relative;
      top: -250px;
      width: 97%;
      max-width: 1400px;
    }

    .section-contents {
      background: #fff;
      box-shadow: 0 16px 20px rgba(0,0,0,0.1);
      padding: 50px;
      width: 700px;
      position: absolute;
      z-index: 10;
      bottom: 90px;

      h3 {
        font-size: 4rem;
        letter-spacing: 0.05em;
        position: relative;
        font-family: "Bebas Neue", cursive;

        span {
          display: inline-block;
          font-size: 2rem;
          color: #ccc;
          margin-left: 15px;
        }

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          display: inline-block;
          width: 65px;
          height: 3px;
          background: linear-gradient(to right, #e34b08,#dbad13);
        }
      }

      h4 {
        font-size: 2rem;
        margin: 25px 0 15px;
        @include media(s) {
          line-height: 1.5;
          margin: 15px 0;
        }
      }
    }

    &:after {
      content: "advertising";
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 18rem;
      line-height: 0.5;
      color: rgba(255,255,255,.7);
      font-family: "Bebas Neue", cursive;
    }
    @include media(s) {
      height: auto;
      margin-top: 0;
      margin-bottom: 40px;

      .section-img {
        position: static;
        top: 0;
        width: 100%;
      }

      .section-contents {
        padding: 20px;
        margin: -70px auto 0;
        width: 90%;
        position: static;
        z-index: 10;
        bottom: 0;
      }
    }
    @include media(s) {
      &:after {
        font-size: 17vw;
        margin: 0 auto;
        left: 0;
        right: 0;
      }
    }
  }

  .sec03 {
    .section-img {
      margin:0 0 0 auto;
    }

    .section-contents {
      right: 0;
    }

    &:after {
      content: "communication";
      bottom: 0;
      left: 0;
      right: auto;
      font-family: "Bebas Neue", cursive;
    }
    @include media(s) {
      .section-img {
        margin-left: 0;
      }
    }
  }

  .sec04 {
    margin-bottom: 0;

    &:after {
      content: "Architecture";
      font-family: "Bebas Neue", cursive;
    }
  }

  .sec05 {
    .sec05-list {
      margin-top: 120px;

      li {
        background: #f8f8f8;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        padding: 50px 30px 50px 50px;
        margin-bottom: 120px;
        @include media(s) {
          padding: 20px;
          margin-bottom: 60px;
        }

        &:nth-child(even) {
          .img-area {
            right: 50px;
          }

          .txt-area {
            margin-right: 45%;
            margin-left: 0;
          }
          @include media(s) {
            .img-area {
              right: 0;
            }

            .txt-area {
              margin-right: 0;
            }
          }
        }

        .img-area {
          width: 37%;
          position: absolute;
          top: -45px;

          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;


            li {
              width: 49%;
              padding: 0;
              box-shadow: 0 0 20px rgba(0,0,0,0.15);

              &:first-child {
                width: 100%;
                margin-bottom: 2%;
              }
              img{
                align-self: flex-start;
              }
            }
          }
          @include media(s) {
            width: 100%;
            position: static;
            margin-top: -40px;

            ul {
              li {
                margin-bottom: 30px;

                &:first-child {
                  width: 100%;
                  margin-bottom: 2%;
                }
              }
            }
          }
        }

        .txt-area {
          width: 52%;
          margin-left: 45%;

          h4 {
            font-size: 2.6rem;
            font-weight: bold;
          }

          .txt-area-sub-ttl {
            font-size: 2.1rem;
            color: #888;
            margin: 0 0 20px;
          }
          @include media(s) {
            width: 100%;
            margin-left: 0;

            h4 {
              line-height: 1.6;
              margin-bottom: 5px;
            }

            .txt-area-sub-ttl {
              font-size: 2.1rem;
              color: #888;
              margin: 0 0 20px;
            }
          }
        }
      }
    }
  }

  .sec06 {
    background: url("../img/top/sec06-bg.jpg") no-repeat center;
    height: 640px;
    background-size: cover;
    margin-bottom: 260px;
    @include media(s) {
      height: auto;
      margin-bottom: 120px;
    }

    .section-in {
      width: 900px;
    }

    .ttl-area,
    .ttl-area span {
      color: #fff;
    }

    .ttl-area h3 {
      &:before {
        background: #fff;
      }
    }

    .sec06-contents {
      background: #fff;
      padding: 70px 40px;
      box-shadow: 0 0 25px rgba(0,0,0,0.1);
      margin-top: 30px;

      p {
        text-align: center;
        line-height: 2.6;
        font-size: 2rem;
        margin-bottom: 50px;
      }

      .btn-area {
        text-align: center;
      }
      @include media(s) {
        padding: 30px 20px;
        margin-bottom: -90px;

        p {
          font-size: 3.2vw;
          margin-bottom: 20px;
        }
      }
    }
  }
}
//--------------------------------------------
//overview
//--------------------------------------------

#overview {
  .overview-bg {
    background: url("../img/overview/main-v.jpg") no-repeat center;
    background-size: cover;
  }

  .sec01 {
    .section-in {
      margin-top: -80px;
      background: #fff;
      padding: 80px 80px 180px;
      text-align: center;
      position: relative;

      h3 {
        font-size: 2.6rem;
        margin-bottom: 30px;
      }

      p {
        font-size: 1.8rem;
        line-height: 2.6;
      }

      .reaflant-txt {
        position: absolute;
        bottom: 60px;
        right: 60px;
        width: 330px;
      }
    }
    @include media(s) {
      .section-in {
        margin-top: 0;
        padding: 40px 20px;

        h3 {
          margin-bottom: 20px;
        }

        p {
          line-height: 2;
        }

        .reaflant-txt {
          position: static;
          bottom: auto;
          right: auto;
          width: 70%;
          margin: 20px auto 0;
        }
      }
    }
  }

  .sec02 {
    .sec02-bg-orange {
      background: linear-gradient(to right, #e34b08, #dbad13 100%, #dbad13 50%, #e34b08);
      height: 360px;
      position: relative;

      .ttl-area {
        @include center;
        color: #fff;
        transform: translate(-50%, -65%);

        h3 {
          &:before {
            background: #fff;
          }
        }

        span {
          color: #fff;
        }
      }
      @include media(s) {
        height: auto;
        position: static;

        .ttl-area {
          position: static;
          transform: none;
          padding: 30px 0;

          h3 {
            &:before {
              background: #fff;
            }
          }

          span {
            color: #fff;
          }
        }
      }
    }

    .section-in {
      margin-top: -80px;
      padding: 80px 80px 120px;
      background: #fff;

      dl {
        display: flex;
        border-bottom: 1px solid #eee;
        justify-content: space-between;

        dt {
          width: 17%;
          padding: 25px 0;
        }

        dd {
          width: 78%;
          padding: 25px 0;
          position: relative;
          .wd140{
            width: 180px;
            display: inline-block;
            @include media(s) {
              display: block;
              width: auto;
            }
          }
          .icon_makeshop{
            width: 100px;
            position: absolute;
            right: 0;
            bottom: 25px;
          }
        }
      }
      @include media(s) {
        margin-top: 0;
        padding: 40px 20px;

        dl {
          dt {
            width: 23%;
            padding: 15px 0;
          }

          dd {
            width: 72%;
            padding: 15px 0;
            .icon_makeshop{
              width: 20%;
              bottom: 10px;
          }
          }
        }
      }
    }
  }
}
//--------------------------------------------
//contact
//--------------------------------------------

#contact {
  .contact-bg {
    background: url("../img/contact/main-v.jpg") no-repeat center;
    background-size: cover;
  }

  .sec01 {
    .section-in {
      margin-top: -80px;
      background: #fff;
      padding: 80px 80px 180px;
      text-align: center;
      position: relative;
      @include media(s) {
        margin-top: 0;
        padding: 40px 20px;
      }

      .formTbl {
        width: 100%;

        th {
          text-align: left;
        }

        tr {
          border-bottom: 1px solid #eee;
          margin-bottom: 33px;

          & > * {
            padding: 25px 0;
            vertical-align: middle;
            width: 70%;
          }
        }

        th {
          width: 200px;
          line-height: 1.5em;

          .required {
            color: #fff;
            width: 50px;
            background: #ff8000;
            padding: 10px;
          }

          .th-width {
            width: 160px;
            float: left;
            text-align: right;
            margin-right: 20px;
          }
          @include media(s) {
            .required {
              text-align: center;
              padding: 3px;
            }

            .th-width {
              float: none;
              margin-right: 0;
              font-size: 16px;
            }
          }
        }

        td {
          @include media(s) {
            padding-top: 10px;
            margin-bottom: 30px;
          }
        }

        .address-td {
          display: flex;
          width: 100%;
          justify-content: space-between;
          flex-wrap: wrap;

          input {
            width: 30% !important;

            &:last-child {
              width: 67% !important;
            }
          }
          @include media(s) {
            input {
              width: 50% !important;
              margin-bottom: 10px;

              &:last-child {
                width: 100% !important;
              }
            }
          }
        }

        input,
        textarea {
          background: #f4f4f4;
          border: 0;
          box-sizing: border-box;
          font-size: 16px;
          padding: 0 1em;
          outline: none;
        }

        input {
          &[type="text"],
          &[type="email"],
          &[type="tel"] {
            width: 100%;
            height: 60px;
            line-height: 60px;
          }

          &[type="number"],
          &[name="zip"] {
            width: 30%;
          }

          &::placeholder {
            color: #bbb;
          }
        }

        .address {
          width: 68% !important;
        }

        .bd input[type="number"] {
          width: 20%;
        }

        textarea {
          width: 100%;
          height: 8em;
          line-height: 1.4em;
          padding: 1em;
        }
      }

      form {
        .privacyArea {
          height: 300px;
          border: 1px solid #f2f2f2;
          padding: 40px;
          text-align: left;
          overflow-y: scroll;
          line-height: 1.6em;
          letter-spacing: 0.05em;
          margin: 65px 0 40px;
          @include media(s) {
            padding: 20px;
          }

          h4 {
            font-size: 2.2rem;
            margin-bottom: 15px;
          }

          dt {
            font-weight: bold;
            margin-top: 1em;
          }

          dd {
            margin-top: 0.5em;
          }

          .establishment {
            text-align: right;
            margin-top: 50px;
          }
        }
      }
      @media screen and (max-width: 700px) {
        .formTbl {
          td,
          th {
            display: block;
            width: 100%;
            padding-bottom: 0;
          }

          th .required {
            display: inline-block;
            margin-left: 0.5em;
          }
        }

        .event .eventList .tbl3 td {
          padding: 0 1em 1em;

          + td {
            border-top: 0;
          }

          &.area.center {
            text-align: left;
            font-weight: bold;
            padding-top: 1em;
          }
        }
      }
    }
  }

  .default-btn {
    &:hover {
      margin-top: 3px;
      margin-bottom: 0;
    }
  }

  .thanks-sec{
    text-align: center;
    h1{
      font-size: 3rem;
      margin-bottom: 20px;
    }
    .section-in{
      @include center;
      margin-top: 0;
      .default-btn{
        margin-top: 10px;
      }
    }
  }
}
